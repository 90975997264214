<template>
  <!-- row -->
  <div class="row tm-content-row tm-mt-big">
    <div class="col-xl-12 col-lg-12 tm-md-12 tm-sm-12 tm-col">
      <div class="bg-white tm-block h-100">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <h2 class="tm-block-title d-inline-block">{{ url }} <small>( last 100 entries )</small></h2>
          </div>
          <div class="col-md-4 col-sm-12 text-right">
            <router-link to="/dashboard" class="btn btn-small btn-primary">Back </router-link>
            <!--<i class="fas fa-spinner ml-2" @click="loadData" style="cursor: pointer;"></i> -->

          </div>
        </div>
        <div>
          <line-chart :cData="theChartData?.chartData"></line-chart>
        </div>
        <div class="table-responsive">

          <table class="table table-hover table-striped tm-table-striped-even mt-3 table-sm ">
            <thead>
              <tr class="tm-bg-gray">
                <th scope="col">&nbsp;</th>
                <!-- <th scope="col">Web address</th> -->
                <th scope="col" class="text-center">Start Date</th>
                <th scope="col" class="text-center">End Date</th>
                <th scope="col" class="text-center">Status</th>
                <th scope="col" class="text-center">Duration</th>
                <th scope="col">Error</th>
                <th scope="col">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!(pingrs?.length > 0)">
                <th scope="row" colspan="6">
                  <h4>No data available yet! </h4>
                </th>
              </tr>
              <tr v-else v-for="p in pingrs" :key="p.id">
                <th scope="row">
                  <!--<input type="checkbox" aria-label="Checkbox">-->
                </th>
                <!-- <td class="tm-product-name">{{ p.url }}</td> -->
                <td class="text-center">{{  formatDate(p.startDate) }} </td>
                <td class="text-center"> {{ formatDate(p.endDate) }} </td>
                <td class="text-center">{{ p.statusCodeResponse }}</td>
                <td class="text-center">{{ p.duration.toFixed(2) }} sec</td>
                <td>{{ p.errorResponse }}</td>
                <td>
                  <a @click="deleteById(p.id)">
                    <i class="fas fa-trash-alt tm-trash-icon mr-2"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="tm-table-mt tm-table-actions-row">
          <div class="tm-table-actions-col-left">
            <button hidden class="btn btn-danger">Delete Selected Items</button>
          </div>
          <div hidden class="tm-table-actions-col-right">
            <span class="tm-pagination-label">Page</span>
            <nav aria-label="Page navigation" class="d-inline-block">
              <ul class="pagination tm-pagination">
                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item">
                  <span class="tm-dots d-block">...</span>
                </li>
                <li class="page-item"><a class="page-link" href="#">13</a></li>
                <li class="page-item"><a class="page-link" href="#">14</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import LineChart from "@/components/LineChart.vue";


export default {
  name: 'DashboardView',
  components: { LineChart },
  computed: {
    ...mapGetters(['isAuthenticated']),
    theChartData() {
      return {
        chartData: {
          labels: this.pingrs.filter(x=>x.statusCodeResponse == "OK").map(x => new Date( x.startDate).toLocaleString()).slice().reverse(),
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#0266c4',
              data: this.pingrs.filter(x=>x.statusCodeResponse == "OK").map(x => x.duration.toFixed(2)).slice().reverse()
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      id: undefined,
      url: "",
      pingrs: [],
      chartOptions: {
        responsive: true

      },
    }
  },
  methods: {
    async loadData() {
      if (!this.id) return;
      const resp = await fetch(`/api/pingr/${this.id}/details`);
      var data = await resp.json();
      this.pingrs = data?.histories;
      this.url = data?.url;
    },
    async deleteById(id) {
      if (!id) return;
      if (confirm("Should we delete it?")) {
        await fetch(`/api/pingr/${id}/details`, { method: "DELETE" });
        await this.loadData();
      }
    },
    formatDate(d) {
      if (!d) return;
      const dt = new Date(d);
      return `${dt.getFullYear()}-${dt.getMonth()}-${dt.getDate()} ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`;
    }
  },
  mounted() {
    this.id = this.$route.params?.id;
    if (this.id)
      this.loadData();
  }
}
</script>
