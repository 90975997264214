import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/DashboardView.vue'
import EditView from '../views/EditView.vue'
import LoginView from '../views/LoginView.vue'
import DetailsView from "@/views/DetailsView.vue";
import SettingsView from '@/views/SettingsView.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: HomeView
  },
  {
    path: '/edit',
    name: 'edit',
    component: EditView
    
  },
  {
    path: '/edit/:id',
    name: 'edititem',
    component: EditView
  },
  {
    path: '/details/:id',
    name: 'details',
    component: DetailsView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView  
  }  ,
  {
    path: '/login',
    name: 'login',
    component: LoginView  
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
