<template>
  <!-- row -->
  <div class="row tm-content-row tm-mt-big">
    <div class="col-xl-12 col-lg-12 tm-md-12 tm-sm-12 tm-col">
      <div class="bg-white tm-block h-100">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <h2 class="tm-block-title d-inline-block">sources & statuses <sup v-show="isLoading">(loading...)</sup></h2>

          </div>
          <div class="col-md-4 col-sm-12 text-right">
            <i class="fas fa-spinner mr-3" @click="loadData" style="cursor: pointer;"></i>
            <router-link to="edit" class="btn btn-small btn-primary">Add New </router-link>            
          </div>
        </div>
        <div class="table-responsive">

          <table class="table table-hover table-striped tm-table-striped-even mt-3">
            <thead>
              <tr class="tm-bg-gray">
                <th scope="col">&nbsp;</th>
                <th scope="col">Web address</th>
                <th scope="col" class="text-center">Interval</th>
                <th scope="col" class="text-center">Disabled</th>
                <th scope="col">Next run</th>
                <th scope="col">Last run</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!(pingrs?.length > 0)">
                <th scope="row" colspan="6">
                  <h4>No entries available yet! Add some using Add new button.</h4>
                </th>
              </tr>
              <tr v-else v-for="p in pingrs" :key="p.id"
                :class="{ 'table table-danger': p.lastExecutionIsSuccess == false }">
                <th scope="row">
                  <!--<input type="checkbox" aria-label="Checkbox">-->
                  <router-link :to="`/details/${p.id}`"><i class="fas fa-eye tm-eye mr-2 tm-site-icon"></i></router-link>
                </th>
                <td :class="{ 'strikethrough': p.disabled }" class="tm-product-name1">
                  <!-- <router-link :to="`/details/${p.id}`"> -->
                     {{ p.title ?? p.url }} 
                    <!-- </router-link>                  -->
                </td>
                <td class="text-center">{{ p.interval }} minutes</td>
                <td class="text-center"> {{ p.disabled ? 'YES' : 'No' }} </td>
                <td><span v-show="!p.disabled">{{ formatDate(p.nextExecution) }}</span></td>
                <td>{{ formatDate(p.lastExecution) }}</td>
                <td>{{ p.lastExecutionIsSuccess == true ? "OK" : p.lastExecutionIsSuccess == false ? "NOK!" : "-" }}
                </td>
                <td>
                  <router-link :to="`/details/${p.id}`"><i class="fas fa-eye tm-eye tm-action-icon mr-2 tm-site-icon"></i></router-link>
                  <a @click="deleteById(p.id)"><i class="fas fa-trash-alt tm-trash-icon mr-2"></i></a>
                  <router-link :to="`/edit/${p.id}`"><i class="fas fa-edit tm-edit tm-site-icon tm-action-icon  mr-2"></i></router-link>
                  <a @click="refreshById(p.id)"><i class="fas fa-redo tm-redo tm-action-icon tm-site-icon mr-2"></i></a>
                  
                </td>
              </tr>

            </tbody>
          </table>
        </div>

        <div class="tm-table-mt tm-table-actions-row">
          <div class="tm-table-actions-col-left">
            <button hidden class="btn btn-danger">Delete Selected Items</button>
          </div>
          <div hidden class="tm-table-actions-col-right">
            <span class="tm-pagination-label">Page</span>
            <nav aria-label="Page navigation" class="d-inline-block">
              <ul class="pagination tm-pagination">
                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item">
                  <span class="tm-dots d-block">...</span>
                </li>
                <li class="page-item"><a class="page-link" href="#">13</a></li>
                <li class="page-item"><a class="page-link" href="#">14</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardView',
  computed: { ...mapGetters(['isAuthenticated']) },
  data() {
    return {
      pingrs: [],
      isLoading: false
    }
  },
  components: {
    //    HelloWorld
  },
  methods: {
    async loadData() {

      try {
        this.isLoading = true;
        console.log("Loading data from api at ", Date.now())
        const resp = await fetch("/api/pingr");
        this.pingrs = await resp.json();
        this.isLoading = false;
      } catch (e) { this.isLoading = false; }
    },
    async deleteById(id) {
      if (!id) return;
      if (confirm("Should we delete it?")) {
        await fetch(`/api/pingr/${id}`, { method: "DELETE" });
        await this.loadData();
      }
    },
    async refreshById(id) {
      if (!id) return;
      try {
        this.isLoading = true;
        await fetch(`/api/pingr/${id}/refresh`);
        await this.loadData();
      } catch (e) {
        console.log(e.message);
      }
      finally {
        this.isLoading = false;
      }
    },
    formatDate(d) {
      if (!d) return;
      const dt = new Date(d);
      return `${dt.getFullYear()}-${dt.getMonth()}-${dt.getDate()} ${dt.getHours()}:${dt.getMinutes()}`;
    }
  },
  mounted() {
    this.loadData();
    this.timer = setInterval(this.loadData, 30000);
  },
  beforeDestroy() {
    // Make sure to clear the interval when the component is destroyed
    clearInterval(this.timer);
  }

}
</script>

<style scoped>
.strikethrough {
  text-decoration: line-through;
}
</style>
