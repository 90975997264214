<template>
      <div class="row tm-mt-big">
        <div class="col-xl-8 col-lg-10 col-md-12 col-sm-12 mx-auto">
          <div class="bg-white tm-block">
            <div class="row">
              <div class="col-12">
                <h2 class="tm-block-title d-inline-block"> Settings</h2>
              </div>
            </div>
            <div class="row mt-4 tm-edit-product-row">
              <div class="col-xl-12 col-lg-12 col-md-12">
                
                <form @submit.prevent="submit" action="#" class="tm-edit-product-form">
                  <div class="input-group mb-3">
                    <label for="name" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Notifications email
                    </label>
                    <input v-model="settings.email" required  placeholder="name@comp.com" type="email"  id="name" name="name" class="form-control validate col-xl-9 col-lg-8 col-md-8 col-sm-7">
                  </div>
                  <div hidden  class="input-group mb-3">
                    <label for="category" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Price plan</label>
                    <select class="custom-select col-xl-5 col-lg-5 col-md-5 col-sm-4" v-model="settings.planId" >
                      <option v-for="x in plans" :key="x.id"  :value="x.id">{{ x.text }}</option>
                    </select>
                  </div>
                  
                  
                  <div hidden class="input-group mb-3">
                    <label for="description" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 mb-2">Description</label>
                    <textarea  class="form-control validate col-xl-9 col-lg-8 col-md-8 col-sm-7" rows="3" placeholder="Details about this address" ></textarea>
                  </div>
                 
                  <div class="input-group mb-3">
                    <label for="stock" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">No error email
                    </label>
                    <input v-model="settings.noErrorEmail"  type="checkbox" aria-label="Checkbox" />
                  </div>

                  <div hidden class="input-group mb-3">
                    <label for="expire_date" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Expire
                                        Date
                    </label>
                    <input placeholder="Expire Date" value="2018-10-28" id="expire_date" name="expire_date" type="text" class="form-control validate col-xl-9 col-lg-8 col-md-8 col-sm-7"
                      data-large-mode="true">
                  </div>

                  <div class="input-group mb-3">
                    <div class="ml-auto col-xl-8 col-lg-8 col-md-8 col-sm-7 pl-0">
                        
                      <button type="submit" class="btn btn-primary"> Save </button> &nbsp;
                      <router-link to="/dashboard"  class="btn btn-primary">Cancel</router-link> &nbsp;
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name:"SettingsView",
  computed:{...mapGetters(['isAuthenticated'])},
  data(){return {
    id:undefined,
    plans:[
      {
        id:1,
        text:" 1 ping min 60 minutes: 0 €/month"
      },
      {
        id:2,
        text:" 5 ping min 10 minutes: 5 €/month"
      },{
        id:3,
        text:" 20 ping min 10 minutes: 10 €/month"
      }

    ],
    settings:{
    email:"",
    planId:1,
    noErrorEmail:false
  },
  
  }},
  methods:{
    async submit(){
      
      await fetch("/api/settings",{
        method: "POST",
        body: JSON.stringify( this.settings)
      });

      this.$router.push("/dashboard");
    },
    async load(){
      var data = await fetch(`/api/settings`);
      this.settings =  await data.json();
    }
  },
  mounted(){
    this.load();    
  }
}
</script>