<template>
      <!-- row -->
      <div class="row tm-mt-big mx-auto">
            <div class="col-12 1mx-auto">
                <!-- <div class=" justify-content-center"> -->
                    <img src="/img/landing.png" loading="lazy" class="img-fluid mx-auto d-block"/>
                <!-- </div> -->

                <div class="bg-white1 tm-block" >
                    <!-- <div class="row">
                        <div class="col-12 text-center">
                            <h2 class="tm-block-title mt-3">
                              <i class="fas fa-lock mr-2"></i>
                              Login / Register
                            </h2>
                        </div>
                    </div> -->
                
                    <div class="row mt-2">
                        <button @click="login" class="btn btn-primary d-inline-block mx-auto bg-white "><i class="fas fa-lock mr-2"></i> GitHub Login / Register</button>
                        
                        <div class="col-12" hidden>
                          <div class="input-group mt-3">
                           
                                </div>

                            <form hidden action="index.html" method="post" class="tm-login-form">
                                <div class="input-group">
                                    <label for="username" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Username</label>
                                    <input name="username" type="text" class="form-control validate col-xl-9 col-lg-8 col-md-8 col-sm-7" id="username" value="admin" required>
                                </div>
                                <div class="input-group mt-3">
                                    <label for="password" class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-form-label">Password</label>
                                    <input name="password" type="password" class="form-control validate" id="password" value="1234" required>
                                </div>
                                <div class="input-group mt-3">
                                    <button type="submit" class="btn btn-primary d-inline-block mx-auto">Login</button>
                                </div>
                                <div class="input-group mt-3">
                                    <p><em>Just put a character to login.</em></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
</template>

<script>

export default {
  name: 'HomeView',
    methods:{
    login(){
       window.location.href = "/.auth/login/github?post_login_redirect_uri=/dashboard" ;
   }
  }
}
</script>

