import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: false
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated
  },
  mutations: {
    SET_AUTH:(state, isAuthed) => state.isAuthenticated  = isAuthed
  },
  actions: {
    async doLogin({commit}){
      const response = await fetch('/.auth/me');
      const resp = await response.json();
      // console.log('do we have user: ', !!resp?.clientPrincipal);
      commit('SET_AUTH', !!resp?.clientPrincipal)  
    }
  },
  modules: {
  }
})
